html,
body {
  height: 100%;
  font-family: sans-serif;
  font-size: 10px;
}

/* Section styles */
main {
  height: 100%;
  width: 100%;
  padding: 8px;
  display: grid;
  align-content: flex-start;
  justify-content: center;
}

.Form {
  margin: 0;
  display: grid;
  align-content: center;
  justify-content: center;
  font-size: 3rem;
}

.Response {
  margin: 16px 0;
  font-weight: 600;
  text-transform: capitalize;
  font-size: 5rem;
}

.Response span {
  transition: 0.3s all;
}

.Response .empty {
  opacity: 0;
}

.Response .weather {
  opacity: 1;
}

/* DONE: Add opacity setting for loading state */
.Response .loading {
  opacity: 0.1; 
}

/* TODO: Add opacity setting for loading state */

/* Desktop styles */
@media only screen and (min-width: 768px) {
  main {
    align-content: center;
  }
  .Response {
    text-align: center;
  }
}
