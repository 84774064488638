* {
  box-sizing: border—box;
  margin: 0;
  padding: 0;
}

body {
font-family: Arial, Helvetica, sans-serif;
line-height: 1.4;
}

a {
color: 0#333;
text-decoration: none;
}

.container {
    padding: 0 1rem;
}
 

.SubmitBtn {
    display: inline-block;
    border: none;
    background: #555;
    color: #fff;
    padding: 7px 20px;
    cursor: pointer;

}

.SubmitBtn:hover{
    background: #666;
}